import { Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import InstallMentApi from "../../../api/installment/installment.api";
import OptionsApi from "../../../api/option/option.api";
import ButtonCustom from "../../../components/button/buttonCustom";
import PreviewImage from "../../../components/image/previewImage";
import HelperText from "../../../components/input/helperText";
import InputTextField from "../../../components/input/inputTextField";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import { colors } from "../../../constants/colors";
import { numberFormat } from "../../../utils/common";
import { CouponRes, FromRegister, Product } from "../type-register";
import * as Styles from "./layout-product.style";
import useWindowSize from "../../../utils/useWindowSize";
import { REGISTER_TYPE } from "../../../utils/enum";
import CouponInput from "../components/coupon";
import { RESPONSIVE_FULL, RESPONSIVE_HALF_XS_FULL } from "../constants/constants";
import { RESPONSIVE_HALF } from "../constants/constants";
import EstimateText from "../components/estimate-text";

const initStateErrorMessage = {
  PRODUCT_HAND: ``,
  PRODUCT_GROUP: ``,
  PRODUCT_MODEL: ``,
  PRODUCT_STORAGE: ``,
  PRODUCT_COLOR: ``,
  PRODUCT_PERIOD: ``,
  PRODUCT_COMBO: ``,
  PRODUCT_BRANCH: ``,
  COUPON: ``,
};

const { TURN1, TURN2 } = REGISTER_TYPE;

const ININT_PROD = { code: "", text: "" };

interface props {
  data?: any;
  checkedItems?: any;
  onClickNext: () => void;
  onClickBack: () => void;
  onChange?: (value: any) => void;
  checkedItemsVal?: (value: any) => void;
  optionsPeriodChoice: any[];
  setOptionsPeriodChoice: (value: any[]) => void;
}
export default function LayoutProductTurn2(props: props) {
  const handRef = useRef<HTMLDivElement | null>(null);
  const groupRef = useRef<HTMLDivElement | null>(null);
  const modelRef = useRef<HTMLDivElement | null>(null);
  const storageRef = useRef<HTMLDivElement | null>(null);
  const colorRef = useRef<HTMLDivElement | null>(null);
  const periodRef = useRef<HTMLDivElement | null>(null);
  const comboRef = useRef<HTMLDivElement | null>(null);
  const branchRef = useRef<HTMLDivElement | null>(null);
  const couponRef = useRef<HTMLDivElement | null>(null);
  const { optionsPeriodChoice, setOptionsPeriodChoice } = props;

  const [width] = useWindowSize();
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);
  const [type, setType] = useState<string>(props.data?.type || TURN2);
  const [data, setData] = useState<FromRegister>(props.data);
  const [productGroup, setProuctGroup] = useState<string>(props.data?.productGroup);
  const [productHand, setProductHand] = useState<string>(props.data?.productHand || "NEW");
  const [productModel, setProductModel] = useState<any[]>([]);
  const [productCode, setProductCode] = useState<string>(props.data?.product?.code || "");
  const [product, setProduct] = useState<Product>(props.data?.product?.model || { ...ININT_PROD });

  const [storageModel, setStorageModel] = useState<any[]>([]);
  const [storage, setStorage] = useState<Product>(props.data?.product?.storage || { ...ININT_PROD });
  const [colorModel, setColorModel] = useState<any[]>([]);
  const [color, setColor] = useState<Product>(props.data?.product?.color || { ...ININT_PROD });

  const [productTurnModel, setProductTurnModel] = useState<any[]>([]);
  const [productTurnCode, setProductTurnCode] = useState<string>(props.data?.productTurn?.code || "");
  const [productTurn, setProductTurn] = useState<Product>(props.data?.productTurn?.model || { ...ININT_PROD });
  const [storageTurnModel, setStorageTurnModel] = useState<any[]>([]);
  const [storageTurn, setStorageTurn] = useState<Product>(props.data?.productTurn?.storage || { ...ININT_PROD });
  const [colorTurnModel, setColorTurnModel] = useState<any[]>([]);
  const [colorTurn, setColorTurn] = useState<Product>(props.data?.productTurn?.color || { ...ININT_PROD });

  const [installments, setInstallMents] = useState<number>(props.data?.installments || 0);
  const [period, setPreiod] = useState<number>(props.data?.period || 0);
  const [downPayment, setDownPayment] = useState<string>(props.data?.downPayment || "0");
  const [maximum, setMaximum] = useState<number>(props.data?.maximum || 0);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const [branchData, setBranchData] = useState<any[]>([]);
  const [branch, setBranch] = useState<string>(props.data?.interestedBranch);
  const [friendAdvisorCode, setFriendAdvisorCode] = useState<string>(props.data?.friend_advisor_code);

  const [checkedItems, setCheckedItems] = useState<any[]>(props.checkedItems || []);
  const [productComboData, setProductComboData] = useState<any[]>([]);
  const [productCombo, setProductCombo] = useState<any[]>(props.data?.combo || []);
  const [productCombCheckboxData, setProductComboCheckboxData] = useState<any[]>([]);

  const [estimateCondition, setEstimateCondition] = useState<string>(props.data?.condition || "");
  const [discountPeriod, setDiscountPeriod] = useState<any>(props.data?.discountPeriod || {});
  const [coupon, setCoupon] = useState<CouponRes[]>(props.data?.coupon || []);
  const [couponDiscount, setCouponDiscount] = useState<number>(props.data?.coupon_discount || 0);

  useEffect(() => {
    setOptionsPeriodChoice([]);
  }, []);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(data);
    }
    if (props.checkedItemsVal) {
      props.checkedItemsVal(checkedItems);
    }
  }, [data]);

  /* Poduct TURN */
  const loadProductTurn = useCallback(() => {
    let condition: any = {};
    if (type) condition = { ...condition, type: TURN1 };
    OptionsApi.productModel(condition).then((res) => {
      if (res.status === 200) {
        setProductTurnModel(res.data.model);
      }
    });
  }, [type]);

  /* Storage TURN */
  useEffect(() => {
    if (productTurn.text) {
      let condition: any = {};
      if (type) condition = { ...condition, type: TURN1 };
      if (productTurn.text) condition = { ...condition, model: productTurn.text };
      OptionsApi.productStorage(condition).then((res) => {
        if (res.status === 200) {
          setStorageTurnModel(res.data.storage);
        }
      });
    }
  }, [productTurn.text]);

  /* Color TURN */
  useEffect(() => {
    if (type && productTurn.text && storageTurn.text) {
      let condition: any = {};
      if (type) condition = { ...condition, type: TURN1 };
      if (productTurn.text) condition = { ...condition, model: productTurn.text };
      if (storageTurn.text) condition = { ...condition, storage: storageTurn.text };
      OptionsApi.productColor(condition).then((res) => {
        if (res.status === 200) {
          setColorTurnModel(res?.data?.color);
        }
      });
    }
  }, [storageTurn.text]);

  const loadProduct = useCallback(() => {
    let condition: any = {};
    if (type) condition = { ...condition, type: TURN2 };
    if (productGroup) condition = { ...condition, category: productGroup === "iPhone" ? "Smartphone" : "Tablet" };
    if (productHand) condition = { ...condition, productHand };
    OptionsApi.productModel(condition).then((res) => {
      if (res.status === 200) {
        setProductModel(res.data.model);
      }
    });
  }, [type, productGroup, productHand]);

  useEffect(() => {
    loadProduct();
    loadProductTurn();
  }, [loadProduct, loadProductTurn]);

  const loadBranch = useCallback(() => {
    let condition: any = {};
    if (type) {
      condition = { ...condition, type, productHand: productHand };
    }
    OptionsApi.registerBranch(condition).then((res) => {
      if (res.status === 200) {
        setBranchData(res.data.result);
      }
    });
  }, [productHand]);

  useEffect(() => {
    loadBranch();
  }, [loadBranch]);

  useEffect(() => {
    if (product.text) {
      let condition: any = {};
      if (type) condition = { ...condition, type: TURN2 };
      if (product.text) condition = { ...condition, model: product.text };
      if (productHand) condition = { ...condition, productHand };
      OptionsApi.productStorage(condition).then((res) => {
        if (res.status === 200) {
          setStorageModel(res.data.storage);
        }
      });
    }
  }, [product.text]);

  useEffect(() => {
    if (type && product.text && storage.text) {
      let condition: any = {};
      if (type) condition = { ...condition, type: TURN2 };
      if (product.text) condition = { ...condition, model: product.text };
      if (storage.text) condition = { ...condition, storage: storage.text };
      if (productHand) condition = { ...condition, productHand };
      OptionsApi.productColor(condition).then((res) => {
        if (res.status === 200) {
          setColorModel(res.data.color);
        }
      });
    }
  }, [storage.text]);

  useEffect(() => {
    if (product.text && storage.text && color.text && productHand) {
      const condition: any = {
        type,
        model: product.text,
        storage: storage.text,
        color: color.text,
        productHand,
        condition: estimateCondition,
      };
      OptionsApi.getProductCode(condition)
        .then((res) => {
          if (res.status === 200) {
            setProductCode(res?.data?.product_code);
            setOptionsPeriodChoice(res?.data?.period || []);
          }
        })
        .catch(() => {
          setProductCode("");
          setOptionsPeriodChoice([]);
        });
    }
  }, [color.text]);

  useEffect(() => {
    if (product.text && storage.text && productGroup === "iPad") {
      let condition: any = {};
      if (product.text) condition = { ...condition, name: product.text };
      if (storage.text) condition = { ...condition, storage: storage.text };
      OptionsApi.productCombo(condition).then((res) => {
        if (res.status === 200) {
          setProductComboData(res.data.result);
          setProductComboCheckboxData(res.data.result.checkbox);
        }
      });
    }
  }, [productModel, storage.text]);

  useEffect(() => {
    if (type && period && product.code && storage.code && color.code && productCode && period && estimateCondition) {
      calculate();
    }
  }, [period, couponDiscount, productCombo]);

  useEffect(() => {
    const sumCouponDiscount = _.sumBy(coupon, "coupon_discount");
    setCouponDiscount(sumCouponDiscount);
  }, [coupon]);

  const calculate = async () => {
    const payload = {
      type: TURN2,
      product: {
        model: product.text,
        storage: storage.text,
        color: color.text,
      },
      productCode: productCode,
      productHand: productHand,
      period: period,
      condition: estimateCondition,
      combo: productCombo,
      coupon_discount: couponDiscount,
    };
    const res = await InstallMentApi.calculate(payload);
    if (res.status === 200) {
      setDiscountPeriod(res?.data?.discountPeriod);
      setDownPayment(res.data.downPayment);
      setInstallMents(res.data.installments);
      setTotalPrice(res.data.totalPrice);
    }
  };

  const clearData = () => {
    setType(TURN2);
    setErrorMessage(initStateErrorMessage);
    setProuctGroup("iPhone");
    setProductHand("NEW");
    setBranchData([]);
    setProductModel([]);
    setStorageModel([]);
    setColorModel([]);
    setProductCode("");
    setProduct({ ...ININT_PROD });
    setStorage({ ...ININT_PROD });
    setColor({ ...ININT_PROD });
    setBranch("");
    setFriendAdvisorCode("");
    setInstallMents(0);
    setPreiod(0);
    setMaximum(0);
    setDownPayment("0");
    setCheckedItems([]);
    setProductCombo([]);
    setProductComboData([]);
    setProductComboCheckboxData([]);
    setOptionsPeriodChoice([]);
    setProductTurnCode("");
    setProductTurn({ ...ININT_PROD });
    setStorageTurn({ ...ININT_PROD });
    setColorTurn({ ...ININT_PROD });
    setEstimateCondition("");
    setDiscountPeriod({});
    setCoupon([]);
    setCouponDiscount(0);
    setTotalPrice(0);
  };

  const clearDataProductHand = () => {
    setProuctGroup("iPhone");
    setErrorMessage(initStateErrorMessage);
    setStorageModel([]);
    setColorModel([]);
    setProduct({ ...ININT_PROD });
    setStorage({ ...ININT_PROD });
    setColor({ ...ININT_PROD });
    setBranch("");
    setFriendAdvisorCode("");
    setInstallMents(0);
    setPreiod(0);
    setDownPayment("0");
    setCheckedItems([]);
    setProductCombo([]);
    setProductComboData([]);
    setProductComboCheckboxData([]);
    setOptionsPeriodChoice([]);
    setDiscountPeriod({});
    setCoupon([]);
    setCouponDiscount(0);
    setTotalPrice(0);
  };

  const clearDataProductGroup = () => {
    setErrorMessage(initStateErrorMessage);
    setProductModel([]);
    setStorageModel([]);
    setColorModel([]);
    setProduct({ ...ININT_PROD });
    setStorage({ ...ININT_PROD });
    setColor({ ...ININT_PROD });
    setBranch("");
    setFriendAdvisorCode("");
    setInstallMents(0);
    setPreiod(0);
    setDownPayment("0");
    setCheckedItems([]);
    setProductCombo([]);
    setProductComboData([]);
    setProductComboCheckboxData([]);
    setOptionsPeriodChoice([]);
    setDiscountPeriod({});
    setCoupon([]);
    setCouponDiscount(0);
    setTotalPrice(0);
  };

  const clearDataProduct = () => {
    setStorage({ ...ININT_PROD });
    setColor({ ...ININT_PROD });
    setInstallMents(0);
    setDownPayment("0");
    setStorageModel([]);
    setColorModel([]);
    setPreiod(0);
    setCheckedItems([]);
    setProductCombo([]);
    setProductComboData([]);
    setProductComboCheckboxData([]);
    setOptionsPeriodChoice([]);
    setDiscountPeriod({});
    setCoupon([]);
    setCouponDiscount(0);
    setTotalPrice(0);
  };

  const clearDataStorage = () => {
    setColor({ ...ININT_PROD });
    setInstallMents(0);
    setDownPayment("0");
    setColorModel([]);
    setPreiod(0);
    setCheckedItems([]);
    setProductCombo([]);
    setProductComboData([]);
    setProductComboCheckboxData([]);
    setOptionsPeriodChoice([]);
    setDiscountPeriod({});
    setCoupon([]);
    setCouponDiscount(0);
  };

  const clearDataColor = () => {
    setInstallMents(0);
    setDownPayment("0");
    setPreiod(0);
    setOptionsPeriodChoice([]);
    setDiscountPeriod({});
    setCoupon([]);
    setCouponDiscount(0);
  };

  const handleChange = (event: any, name: string) => {
    const { checked } = event.target;
    if (checked) {
      setCheckedItems([...checkedItems, { value: name }]);
    } else {
      setCheckedItems(checkedItems.filter((e: any) => e.value !== name));
      setProductCombo(productCombo.filter((e: any) => e.type !== name));
    }
  };

  const scrollbarFocus = () => {
    const config: ScrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'center',
    };
    if (!product.code) return modelRef.current?.scrollIntoView(config);
    if (!storage.code) return storageRef.current?.scrollIntoView(config);
    if (!color.code) return colorRef.current?.scrollIntoView(config);
    if (!period) return periodRef.current?.scrollIntoView(config);
    if (checkedItems.length > 0 && _.isEmpty(productCombo)) return comboRef.current?.scrollIntoView(config);
    if (!branch) return branchRef.current?.scrollIntoView(config);
  }

  const onSubmit = async () => {
    if (
      !product.code ||
      !storage.code ||
      !color.code ||
      !period ||
      !branch ||
      (checkedItems.length > 0 && _.isEmpty(productCombo))
    ) {
      const msg: any = {
        PRODUCT_MODEL: !product.code ? "โปรดเลือกสินค้า" : "",
        PRODUCT_STORAGE: !storage.code ? "โปรดเลือกความจุ" : "",
        PRODUCT_COLOR: !color.code ? "โปรดเลือกสี" : "",
        PRODUCT_PERIOD: !period ? "โปรดเลือกงวด" : "",
        PRODUCT_COMBO: checkedItems.length > 0 && _.isEmpty(productCombo) ? "โปรดเลือกสินค้า" : "",
        PRODUCT_BRANCH: !branch ? "โปรดเลือกสาขาที่สนใจ" : "",
      };
      setErrorMessage({ ...initStateErrorMessage, ...msg });
      scrollbarFocus();
      return false;
    } else {
      setData({
        ...data,
        productTurn: {
          code: productTurnCode,
          model: productTurn,
          storage: storageTurn,
          color: colorTurn,
        },
        product: {
          code: productCode,
          model: product,
          storage: storage,
          color: color,
        },
        productGroup: productGroup,
        productHand: productHand || "NEW",
        downPayment: downPayment,
        installment: installments,
        installments: installments,
        period: period,
        interestedBranch: branch,
        friend_advisor_code: friendAdvisorCode,
        combo: productCombo,
        discountPeriod: discountPeriod,
        coupon: coupon,
        coupon_discount: couponDiscount,
        totalPrice: totalPrice,
      });
      setTimeout(() => {
        return props.onClickNext && props.onClickNext();
      }, 500);
    }
  };

  const onClickBack = async () => {
    const setDt: any = _.omit(
      {
        ...data,
        type: TURN1,
        product: {
          code: productTurnCode,
          model: productTurn,
          storage: storageTurn,
          color: colorTurn,
        },
        productGroup: productGroup,
        interestedBranch: branch,
        friend_advisor_code: friendAdvisorCode,
        downPayment: "0",
        installments: 0,
        installment: 0,
      },
      ["productTurn", "period", "discountPeriod"]
    );

    clearData();
    setData(setDt);

    setTimeout(() => {
      return props.onClickBack && props.onClickBack();
    }, 500);
  };

  return (
    <Styles.Container>
      <Styles.MarginRow>
        <Col {...RESPONSIVE_HALF_XS_FULL}>
          <PreviewImage
            disabled={width <= 575 ? true : false}
            image={
              color
                ? _.find(colorModel, (val: any) => val.value === color.code)?.image
                : productModel
                  ? _.find(productModel, (val: any) => val.value === product.code)?.image
                  : ""
            }
          />
        </Col>
        <Col {...RESPONSIVE_HALF_XS_FULL}>
          <Row>
            <Col {...RESPONSIVE_FULL}>
              <Typography variant="h6" style={{ color: colors.themeMainColor }} mb={2}>
                สินค้าเก่าของคุณที่ต้องการเทิร์น
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col ref={modelRef} {...RESPONSIVE_HALF_XS_FULL} className="pt-2">
              <AutocompleteSelect
                options={productTurnModel || []}
                value={_.find(productTurnModel, (val: any) => val.value === productTurn.code) || null}
                placeholder={"เลือกสินค้า"}
                getOptionLabel={(option: any) => option.label}
                labelId="productTurn"
                heading="สินค้า"
                required
                disabled={true}
              />
            </Col>
            <Col ref={storageRef} {...RESPONSIVE_HALF_XS_FULL} className="pt-2">
              <AutocompleteSelect
                options={storageTurnModel || []}
                value={_.find(storageTurnModel, (val: any) => val.value === storageTurn.code) || null}
                placeholder={"เลือกความจุ"}
                getOptionLabel={(option: any) => option.label}
                labelId="storageTurn"
                heading="ความจุ"
                required
                disabled={true}
              />
            </Col>
          </Row>

          <Row>
            <Col ref={colorRef} {...RESPONSIVE_HALF_XS_FULL} className="pt-2">
              <AutocompleteSelect
                options={colorTurnModel || []}
                value={_.find(colorTurnModel, (val: any) => val.value === colorTurn.code) || null}
                placeholder={"เลือกสี"}
                getOptionLabel={(option: any) => option.label}
                labelId="colorTurn"
                heading="สี"
                required
                disabled={true}
              />
            </Col>
            <Col {...RESPONSIVE_HALF_XS_FULL} className="pt-2">
              <InputTextField value={numberFormat(maximum)} heading="ราคาสูงสุด" disabled />
            </Col>
          </Row>

          {/* Product ที่ลูกค้าเลือก */}
          <Row>
            <Col {...RESPONSIVE_HALF_XS_FULL} className="mt-2">
              <Typography variant="h6" style={{ color: colors.themeMainColor }} mb={2} mt={2}>
                เลือกสินค้ารุ่นใหม่ที่ต้องการ
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                row
                value={productHand}
                onChange={(e: any) => {
                  clearDataProductHand();
                  setProductHand(e.target.value);
                }}
              >
                <Col {...RESPONSIVE_HALF}>
                  <FormControlLabel
                    value="NEW"
                    control={
                      <Radio
                        sx={(theme) => ({
                          "& .MuiSvgIcon-root": {
                            [theme.breakpoints.down("xl")]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down("lg")]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down("md")]: {
                              fontSize: 30,
                            },
                          },
                        })}
                      />
                    }
                    label="สินค้ามือหนึ่ง"
                  />
                </Col>
                <Col {...RESPONSIVE_HALF}>
                  <FormControlLabel
                    value="SECOND"
                    control={
                      <Radio
                        sx={(theme) => ({
                          "& .MuiSvgIcon-root": {
                            [theme.breakpoints.down("xl")]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down("lg")]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down("md")]: {
                              fontSize: 30,
                            },
                          },
                        })}
                      />
                    }
                    label="สินค้ามือสอง"
                  />
                </Col>
              </RadioGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                row
                value={productGroup}
                onChange={(e: any) => {
                  clearDataProductGroup();
                  setProuctGroup(e.target.value);
                }}
              >
                <Col {...RESPONSIVE_HALF}>
                  <FormControlLabel
                    value="iPhone"
                    control={
                      <Radio
                        sx={(theme) => ({
                          "& .MuiSvgIcon-root": {
                            [theme.breakpoints.down("xl")]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down("lg")]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down("md")]: {
                              fontSize: 30,
                            },
                          },
                        })}
                      />
                    }
                    label="iPhone"
                  />
                </Col>

                <Col {...RESPONSIVE_HALF}>
                  <FormControlLabel
                    value="iPad"
                    control={
                      <Radio
                        sx={(theme) => ({
                          "& .MuiSvgIcon-root": {
                            [theme.breakpoints.down("xl")]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down("lg")]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down("md")]: {
                              fontSize: 30,
                            },
                          },
                        })}
                      />
                    }
                    label="iPad"
                  />
                </Col>
              </RadioGroup>
            </Col>
          </Row>

          <Styles.MarginRow ref={modelRef}>
            <AutocompleteSelect
              options={productModel || []}
              value={_.find(productModel, (val: any) => val.value === product.code) || null}
              placeholder={"เลือกสินค้า"}
              getOptionLabel={(option: any) => option.label}
              labelId="product"
              onChange={(event, value, reason) => {
                if (value) {
                  setErrorMessage({ ...errorMessage, PRODUCT_MODEL: "" });
                  setProduct({ code: value.value, text: value.label });
                  clearDataProduct();
                } else if (reason === "clear") {
                  setProduct({ ...ININT_PROD });
                  clearDataProduct();
                }
              }}
              heading="สินค้า"
              helperText={errorMessage.PRODUCT_MODEL}
              required
            />
          </Styles.MarginRow>
          <Row>
            <Col ref={storageRef} {...RESPONSIVE_HALF_XS_FULL} className="pt-2">
              <AutocompleteSelect
                options={storageModel || []}
                value={_.find(storageModel, (val: any) => val.value === storage.code) || null}
                placeholder={"เลือกความจุ"}
                getOptionLabel={(option: any) => option.label}
                labelId="storage"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, PRODUCT_STORAGE: "" });
                    setStorage({ code: value.value, text: value.label });
                    clearDataStorage();
                  } else if (reason === "clear") {
                    setStorage({ ...ININT_PROD });
                    clearDataStorage();
                  }
                }}
                heading="ความจุ"
                required
                helperText={errorMessage.PRODUCT_STORAGE}
              />
            </Col>
            <Col ref={colorRef} {...RESPONSIVE_HALF_XS_FULL} className="pt-2">
              <AutocompleteSelect
                options={colorModel || []}
                value={_.find(colorModel, (val: any) => val.value === color.code) || null}
                placeholder={"เลือกสี"}
                getOptionLabel={(option: any) => option.label}
                labelId="color"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, PRODUCT_COLOR: "" });
                    setColor({ code: value.value, text: value.label });
                    clearDataColor();
                  } else if (reason === "clear") {
                    setColor({ ...ININT_PROD });
                    clearDataColor();
                  }
                }}
                heading="สี"
                required
                helperText={errorMessage.PRODUCT_COLOR}
              />
            </Col>
          </Row>

          <Styles.MarginRow>
            <div>
              <span>งวด</span>
              <span style={{ color: colors.danger }}>*</span>
            </div>
            <RadioGroup
              ref={periodRef}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              row
              value={_.find(optionsPeriodChoice, (val: any) => val.value === period)?.value || null}
              onChange={(e: any) => {
                if (e) {
                  setErrorMessage({ ...errorMessage, PRODUCT_PERIOD: "" });
                  setPreiod(parseInt(e.target.value));
                }
              }}
            >
              {optionsPeriodChoice?.map((el: any, idx: number) => {
                return (
                  <FormControlLabel
                    value={el.value}
                    key={idx}
                    control={
                      <Radio
                        key={idx}
                        sx={(theme) => ({
                          "& .MuiSvgIcon-root": {
                            [theme.breakpoints.down("xl")]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down("lg")]: {
                              fontSize: 24,
                            },
                            [theme.breakpoints.down("md")]: {
                              fontSize: 30,
                            },
                          },
                        })}
                      />
                    }
                    label={el.label}
                  />
                );
              })}
            </RadioGroup>
            {errorMessage.PRODUCT_PERIOD !== "" && (
              <div>
                <HelperText label={errorMessage.PRODUCT_PERIOD} />
              </div>
            )}
          </Styles.MarginRow>

          <Styles.MarginRow>
            <CouponInput
              data={{ ...data, product: { ...data?.productTurn } }}
              initStateErrorMessage={initStateErrorMessage}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              discountPeriod={discountPeriod}
              coupon={coupon}
              setCoupon={setCoupon}
            />
          </Styles.MarginRow>

          {productCombCheckboxData?.length > 0 && (
            <Styles.MarginRow>
              <>
                <div>อุปกรณ์เสริม</div>
                {productCombCheckboxData.map((item: any) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            _.find(checkedItems, (val: any) => (val.value === item.values ? true : false)) || false
                          }
                          onChange={(e: any) => {
                            handleChange(e, item.values);
                          }}
                        />
                      }
                      label={item.values}
                    />
                  );
                })}
              </>
            </Styles.MarginRow>
          )}
          {checkedItems &&
            checkedItems.map((e: any) => {
              return (
                <Styles.MarginRow ref={comboRef}>
                  <AutocompleteSelect
                    options={productComboData[e.value] || []}
                    value={
                      _.find(
                        productComboData[e.value],
                        (val: any) => _.find(productCombo, (e: any) => e.name === val.name) || null
                      ) || null
                    }
                    placeholder={"เลือก Pencil"}
                    getOptionLabel={(option: any) => option.name}
                    labelId="contracttype"
                    onChange={(event, value, reason) => {
                      if (value) {
                        const data = {
                          name: value.name,
                          code: value.code,
                          down_amount: value.down_amount,
                          type: e.value,
                        };
                        setErrorMessage({ ...errorMessage, PRODUCT_COMBO: "" });
                        setProductCombo([data]);
                      } else if (reason === "clear") {
                        setProductCombo(productCombo.filter((i: any) => i.type !== e.value));
                      }
                    }}
                    heading={e.value}
                    helperText={errorMessage.PRODUCT_COMBO}
                    required
                  />
                </Styles.MarginRow>
              );
            })}

          <Styles.MarginRow>
            <Col {...RESPONSIVE_HALF_XS_FULL} className="mt-2">
              <InputTextField value={numberFormat(Number(downPayment))} heading={"จำนวนเงินดาวน์"} disabled />
            </Col>
            <Col {...RESPONSIVE_HALF_XS_FULL} className="mt-2">
              <InputTextField value={numberFormat(installments)} heading="จำนวนเงินผ่อน" disabled />
            </Col>
          </Styles.MarginRow>

          <Styles.MarginRow>
            <Col {...RESPONSIVE_FULL} className="mt-2">
              <Styles.HeaderText style={{ marginTop: 16 }}>
                <InputTextField
                  value={
                    maximum && Number(downPayment) ? numberFormat(Math.abs(Number(maximum) - Number(downPayment))) : ""
                  }
                  heading={Number(maximum) - Number(downPayment) < 0 ? "ดาวน์เพิ่ม" : "เงินทอน"}
                  inputHeight={48}
                  inputProps={{ style: { fontSize: 22 } }}
                  readOnly={true}
                />
              </Styles.HeaderText>
            </Col>
          </Styles.MarginRow>

          <Styles.MarginRow ref={branchRef}>
            <AutocompleteSelect
              options={branchData || []}
              value={_.find(branchData, (val: any) => val.value === branch) || null}
              placeholder={"เลือกสาขา"}
              groupBy={(option: any) => option.region}
              getOptionLabel={(option: any) => option.label}
              labelId="contracttype"
              onChange={(event, value, reason) => {
                if (value) {
                  setErrorMessage({ ...errorMessage, PRODUCT_BRANCH: "" });
                  setBranch(value.value);
                } else if (reason === "clear") setBranch("");
              }}
              heading="สาขาที่สนใจ"
              helperText={errorMessage.PRODUCT_BRANCH}
              required
            />
          </Styles.MarginRow>
          <Styles.MarginRow>
            <Col {...RESPONSIVE_FULL}>
              <InputTextField
                value={friendAdvisorCode}
                onchange={(event: any) => {
                  setFriendAdvisorCode(event.target.value);
                }}
                heading="รหัสผู้แนะนำ (ถ้ามี)"
              />
            </Col>
          </Styles.MarginRow>

          <EstimateText />

          <Styles.MarginRow>
            <Col className="pt-2">
              <ButtonCustom
                variant={"outline"}
                btnStyle={{ width: "100%" }}
                className={"mt-2"}
                textButton={"ย้อนกลับ"}
                onClick={() => {
                  onClickBack();
                }}
              />
            </Col>
            <Col className="pt-2">
              <ButtonCustom
                btnStyle={{ width: "100%" }}
                className={"mt-2"}
                textButton={"ถัดไป"}
                onClick={() => {
                  onSubmit();
                }}
              />
            </Col>
          </Styles.MarginRow>
        </Col>
      </Styles.MarginRow>
    </Styles.Container>
  );
}
